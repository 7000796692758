import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import { RelationFactory } from '~/models/factories/RelationFactory';
import type { RelationResponse, WriteExtranetRelationDto } from '~/types/Relation';
import type { Relation } from '~/models/Relation';
import type {
    LevelMeasurementOverviewResponse,
    LevelMeasurementResponse,
    ReadLevelMeasurementEntry,
} from '~/types/LevelMeasurements';

export default class RelationService extends BaseApiService {
    public static basePath = 'relations';

    /**
     * @description Fetch relation details of logged in user.
     * @returns {Promise<Relation>} Promise with the Relation model as payload
     */
    async fetchRelation(): Promise<Relation> {
        try {
            const response = await this.baseGet<RelationResponse>(
                `${this.createPath(RelationService.basePath)}`,
            );

            return new RelationFactory().toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update relation details.
     * @param {WriteExtranetRelationDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(data: WriteExtranetRelationDto): Promise<null> {
        try {
            return this.basePatch<null>(
                this.createPath(RelationService.basePath),
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchLevelMeasurements(): Promise<ReadLevelMeasurementEntry[]> {
        try {
            const response = await this.baseGet<LevelMeasurementOverviewResponse>(
                this.createPath(`${RelationService.basePath}/levelmeasurements`),
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchLevelMeasurement(levelMeasurementId: number): Promise<ReadLevelMeasurementEntry> {
        try {
            const response = await this.baseGet<LevelMeasurementResponse>(
                this.createPath(`${RelationService.basePath}/levelmeasurements/${levelMeasurementId}`),
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
